<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="6">
        <v-text-field
          :label="$t(this.$route.meta.type+'Number')"
          type="text"
          v-model="item.number"
          clearable
          dense
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          :label="$t('customerNumber')"
          type="text"
          v-model="item.customer.customerNumber"
          clearable
          dense
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" v-if="this.$route.meta.type !== 'credit'">
        <v-checkbox :label="$t('openInvoices')" v-model="item.__Fisdue" value="1" dense />
      </v-col>
      <v-col cols="6" v-if="this.$route.meta.type !== 'credit'">
        <v-checkbox :label="$t('openInvoicesDunningNotSendLong')" v-model="item.__FnotSentDunning" value="1" dense />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'InvoiceFilter',
  props: {
    values: {
      type: Object,
      required: true
    }
  },
  data () {
    return {};
  },
  mounted () {
  },

  computed: {
    // eslint-disable-next-line
    item () {
      return this.initialValues || this.values;
    }
  },
  methods: {}
};
</script>
