<template>
  <v-card v-if="hasRole('ROLE_INVOICE_ADMIN')" class="mx">
    <v-card flat>
      <v-card-text>
        <div>
          <h3 class="font-weight-bold mb-3 black--text">
            {{ $t("DownloadInvoices") }}
          </h3>
          <p>
            {{ $t("DownloadInvoicesExplanation") }}
          </p>
        </div>
        <v-row>
          <v-col cols="4">
            <v-select :items="months" :label="$t('month')" v-model="selectedMonth"/>
          </v-col>
          <v-col cols="4">
            <v-select :items="years" :label="$t('year')" v-model="selectedYear"/>
          </v-col>
          <v-col cols="4" class="flex justify-end align-center">
            <v-btn
              :disabled="downloadDisabled"
              outlined
              @click="downloadInvoices()"
            >
              <v-icon>mdi-download</v-icon>
              <span>{{ $t("Download") }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import DocumentMixin from "@/mixins/DocumentMixin";
import InvoiceMixin from "@/mixins/InvoiceMixin";
import {mapActions} from "vuex";

export default {
  name: "InvoiceDownloader",
  mixins: [DocumentMixin, InvoiceMixin],
  data() {
    return {
      years: [...Array(4).keys()].map((c) => new Date().getFullYear() - c),
      months: [...Array(12).keys()].map((c) => (c < 9 ? "0" + (c + 1) : c + 1)),
      selectedMonth: null,
      selectedYear: null,
    };
  },
  mounted() {
  },

  computed: {
    downloadDisabled() {
      return this.selectedMonth === null || this.selectedYear === null;
    },
  },
  methods: {
    ...mapActions("invoice", {
      actionInvoice: "action",
    }),
    downloadInvoices() {
      this.downloadDocument(
        `/invoices/package?m=${this.selectedYear}-${this.selectedMonth}`,
        `dokumente_${this.selectedYear}-${this.selectedMonth}.zip`,
        true
      );
      this.selectedMonth = null;
      this.selectedYear = null;
    },
  },
};
</script>
