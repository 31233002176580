<template>
  <div class="invoice-list">
    <v-container fluid grid-list-xl>
      <v-layout row wrap>
        <v-flex lg12>
          <Toolbar
            :handle-add="addHandler"
            :enable-refresh="true"
            :handle-refresh="refreshData"
            :title="this.pageTitle"
          />
          <DataFilter :handle-filter="onSendFilter" :handle-reset="resetFilter" :init-filter-state="true">
            <template v-slot:filter>
              <InvoiceFilterForm :values="filters" ref="filterForm"  />
            </template>
          </DataFilter>
          <br />
          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page.sync="options.itemsPerPage"
            :loading="isLoading"
            :loading-text="$t('Loading...')"
            :options.sync="options"
            :server-items-length="totalItems"
            @update:options="onUpdateOptions"
            class="elevation-1"
            item-key="@id"
          >
            <template v-slot:item.customer="{ item }">
              <v-btn plain color="primary dark" @click="openCustomerDialog(resolveCustomer(item['customer']))">
                {{ getCustomerItem(item['customer'], 'customerNumber') }}
              </v-btn>
            </template>
            <template v-slot:item.number="{ item }">
              <v-icon dense v-if="item.type === 'cancellation'">mdi-file-cancel</v-icon>
              <v-icon dense v-else>mdi-file-document-outline</v-icon>
              <template v-if="item.type === 'invoice' && item.cancellationInvoice">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span class="cancelled" v-bind="attrs" v-on="on">
                      {{ item.number }}
                    </span>
                  </template>
                  <span
                    >Rechnung wurde storniert. Storno-Rnr.:
                    {{ getInvoiceItem(item.cancellationInvoice, 'number') }}</span
                  >
                </v-tooltip>
              </template>
              <template v-else-if="item.type === 'invoice' && item.dunnable">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <span>{{ item.number }}</span
                      ><v-icon dense>mdi-exclamation-thick</v-icon>
                    </span>
                  </template>
                  <span>Rechnung ist überfällig. Mahnung erstellen.</span>
                </v-tooltip>
              </template>
              <template v-else>
                <span>{{ item.number }}</span>
              </template>
            </template>
            <template v-slot:item.date="{ item }">
              {{ formatDateTime(item['date'], 'L') }}
            </template>
            <template v-slot:item.total="{ item }"> {{ item['total'] }} €</template>
            <template v-slot:item.frozen="{ item }">
              <v-icon small v-if="item.frozen">mdi-check-circle</v-icon>
              <v-icon small v-else>mdi-close-circle</v-icon>
            </template>
            <template v-slot:item.dunningLevel="{ item }">
              <span v-if="item.dunningLevel && item.dunnings && item.dunnings.length > 0">{{
                item.dunningLevel - 1
              }}</span>
              <v-icon small v-else>mdi-alarm-light-off-outline</v-icon>
            </template>
            <template v-slot:item.paid="{ item }">
              <v-icon small v-if="item.paid">mdi-check-circle</v-icon>
              <v-icon small v-else>mdi-close-circle</v-icon>
            </template>
            <template v-slot:item.action="props">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" class="mr-2" small @click="downloadInvoiceDocument(props.item)">
                    mdi-file-download
                  </v-icon>
                </template>
                <span>{{ $t(props.item.type) }} herunterladen</span>
              </v-tooltip>
              <v-tooltip
                bottom
                v-if="props.item.type === 'invoice' && props.item.frozen && !props.item.cancellationInvoice"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" @click="cancelHandler(props.item)" class="mr-2" small>
                    mdi-close-circle
                  </v-icon>
                </template>
                <span>Rechnung stornieren</span>
              </v-tooltip>
              <v-tooltip
                bottom
                v-if="props.item.type === 'invoice' && props.item.frozen && !props.item.cancellationInvoice"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" @click="correctHandler(props.item)" class="mr-2" small>
                    mdi-file-document-edit
                  </v-icon>
                </template>
                <span>Rechnung korrigieren</span>
              </v-tooltip>
              <v-icon @click="editHandler(props.item)" class="mr-2" small v-if="!props.item.frozen">
                mdi-pencil
              </v-icon>
              <v-icon @click="showHandler(props.item)" class="mr-2" small v-if="props.item.frozen"> mdi-eye</v-icon>
              <v-icon @click="handleDelete(props.item)" class="mr-2" small v-if="!props.item.frozen">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
          <CustomerInfoDialog
            v-model="showCustomerDialog"
            :customer="customerDialogItem"
            :key="customerDialogItem.id"
            @close="showCustomerDialog = false"
          />
        </v-flex>
        <v-row class="mt-3 pa-3">
          <v-col cols="6">
            <InvoiceDownloader />
          </v-col>
        </v-row>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import ListMixin from '../../mixins/ListMixin';
import InvoiceFilterForm from '../../components/invoice/Filter';
import DataFilter from '../../components/DataFilter';
import Toolbar from '../../components/Toolbar';
import ProfileMixin from '@/mixins/ProfileMixin';
import DocumentMixin from '@/mixins/DocumentMixin';
import CustomerMixin from '@/mixins/CustomerMixin';
import InvoiceMixin from '@/mixins/InvoiceMixin';
import InvoiceDownloader from '../../components/invoice/InvoiceDownloader.vue';
import CustomerInfoDialog from '@/components/customer/CustomerInfoDialog';
import { useStorage } from '@vueuse/core';
import _isEqual from 'lodash/isEqual';
import { useRoute } from 'vue-router/composables';

export default {
  name: 'InvoiceList',
  servicePrefix: 'Invoice',
  mixins: [ListMixin, ProfileMixin, DocumentMixin, CustomerMixin, InvoiceMixin],
  components: {
    CustomerInfoDialog,
    Toolbar,
    InvoiceFilterForm,
    DataFilter,
    InvoiceDownloader
  },
  setup() {
    const route = useRoute();
    const state = useStorage(route.meta.type + '-listing-page', {
      options: {
        page: 1,
        sortBy: ['number'],
        sortDesc: [true],
        descending: false,
        itemsPerPage: 15
      },
      filters: {}
    });
    return { state };
  },
  data() {
    return {
      filters: {
        customer: {}
      },
      showCustomerDialog: false,
      customerDialogItem: {}
    };
  },
  computed: {
    ...mapGetters('invoice', {
      items: 'list'
    }),
    ...mapFields('invoice', {
      deletedItem: 'deleted',
      error: 'error',
      isLoading: 'isLoading',
      resetList: 'resetList',
      totalItems: 'totalItems',
      view: 'view',
      handled: 'handled',
      handleAction: 'handleAction'
    }),
    getFilters() {
      return {
        type: this.$route.meta.type === 'invoice' ? ['cancellation', 'invoice'] : ['credit']
      };
    },
    isInvoice() {
      return this.$route.meta.type === 'invoice';
    },
    pageTitle() {
      return this.isInvoice ? this.$t('Invoices') : this.$t('Credits');
    },
    headers() {
      let header = [
        {
          text: this.$route.meta.type === 'invoice' ? this.$t('invoiceNumber') : this.$t('creditNumber'),
          value: 'number'
        },
        { text: this.$i18n.t('customerNumber'), value: 'customer', sortable: false },
        { text: this.$i18n.t('invoiceDate'), value: 'date' },
        { text: this.$i18n.t('invoiceTotal'), value: 'total' },
        {
          text: this.$i18n.t('dunningLevel'),
          value: 'dunningLevel',
          sortable: false,
          align: 'center'
        },
        {
          text: this.$i18n.t('frozen'),
          value: 'frozen',
          sortable: false,
          align: 'center'
        },
        {
          text: this.$i18n.t('paid'),
          value: 'paid',
          sortable: false,
          align: 'center'
        },
        {
          text: this.$i18n.t('Actions'),
          value: 'action',
          sortable: false
        }
      ];

      return header;
    }
  },
  methods: {
    log(m) {
      console.log(m);
    },
    ...mapActions('invoice', {
      getPage: 'fetchAll',
      deleteItem: 'del',
      actionInvoice: 'action',
      clearInvoiceData: 'resetAllData'
    }),
    resetFilter() {
      this.filters = {
        customer: {},
        ...this.getFilters
      };
    },
    handleDelete(item) {
      this.$confirm(this.$t('Are you sure you want to delete this item?'), {
        buttonTrueText: this.$t('Delete'),
        buttonFalseText: this.$t('Cancel')
      }).then((response) => {
        if (!response) {
          return;
        }
        this.deleteHandler(item);
      });
    },
    downloadInvoiceDocument(invoice) {
      this.getAndDownloadDocument(invoice.document);
    },
    cancelHandler(item) {
      const confirmMessage = !item.paid
        ? 'Are you sure you want to cancel this invoice?'
        : 'Are you sure you want to cancel this already paid invoice?';

      this.$confirm(this.$t(confirmMessage), {
        buttonTrueText: this.$t('Yes'),
        buttonFalseText: this.$t('No')
      }).then((response) => {
        if (!response) {
          return;
        }
        this.actionInvoice({ item: item, action: 'cancel' });
      });
    },
    correctHandler(item) {
      this.$confirm(this.$t('invoiceEditMessage'), {
        title: this.$t('invoiceEditTitle'),
        icon: null,
        color: '#4a4a49',
        buttonTrueText: this.$t('yesEdit'),
        buttonFalseText: this.$t('No')
      }).then((response) => {
        if (!response) {
          return;
        }
        this.actionInvoice({ item: item, action: 'correct' });
      });
    },
    openCustomerDialog(customer) {
      this.customerDialogItem = customer;
      this.showCustomerDialog = true;
    },
    refreshData() {
      this.clearInvoiceData();
      this.onSendFilter();
    }
  },
  created() {
    this.filters = { ...this.filters, ...this.getFilters };
    if (typeof this.state.filters === 'undefined' || Object.keys(this.state.filters).length === 0) {
      this.state.filters = this.filters;
    }
    this.$options.servicePrefix = this.isInvoice ? 'Invoice' : 'Credit';
    this.options = this.state.options;
    this.filters = { ...this.state.filters, ...this.getFilters };
    this.onSendFilter();
  },
  watch: {
    handled() {
      if (this.handleAction === 'cancel') {
        this.showMessage(this.$t('InvoiceCancelled'));
        this.handleAction = null;
        this.handled = null;
        this.getPage(); // reload items
      }
      if (this.handleAction === 'correct') {
        this.showMessage(this.$t('InvoiceCorrected'));
        this.$router.push({
          name: 'InvoiceUpdate',
          params: { id: this.handled['@id'] }
        });
        this.handleAction = null;
        this.handled = null;
      }
    },
    options(newOptions, oldOptions) {
      const shallowDiff = Object.entries(newOptions).reduce(
        (diff, [key, value]) => (_isEqual(oldOptions[key], value) ? diff : { ...diff, [key]: value }),
        {}
      );
      if (Object.keys(shallowDiff).length > 0) {
        this.state.options = this.options;
      }
    },
    filters() {
      this.state.filters = this.filters;
    }
  },
};
</script>

<style lang="scss">
.cancelled {
  text-decoration: line-through;
}
</style>
